/* Estilos para el viewlet de la imagen de la cabecera */

.portal-header-titleImage {
    overflow: hidden;
}

.titleImage-wrapper-noHome {
    position: relative;

    .titleImage-box-left {
        background: none;
        position: absolute;
        left: 0;
        right:0;
        bottom: 0;        
    
        .wrap-height {
            overflow: hidden;
            //max-width: 22em;
            max-width: 30em;
            padding: 1em;
            background-color: rgba(255,255,255, .8);
        }
    
        .wrap-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;

            .title-titleImage {
                background-color: inherit !important;
                //font-size: 36pt !important;
                font-size: clamp(1.5rem, 1.1807rem + 1.1881vw, 2.25rem) !important;
                line-height: 1.25 !important;
                font-weight: 700;
            }
    
            a {
                color: #333;
                font-weight: 700;
            }
        }    
    }
}


// .titleImage-wrapper-home,
// .titleImage-wrapper-noHome {position:relative;z-index:1;height:100%; height: 20em;}
// .portal-header-titleImage .wrap {position:relative;}
// .portal-header-titleImage .wrap-height {
//     max-width:50%;
//     display: flex;
//     align-items: center;
//     height: 20em;
// }
// .portal-header-titleImage .titleImage-box-left {color:black;min-height:100%;width:100%;position:absolute;z-index:3;}
// .portal-header-titleImage .titleImage-box-left span.title-titleImage {font-family:Avenir Next W01, Arial, FreeSans, sans-serif;font-size:2.15em;margin:0 0 0.5em;display:block;line-height:90%;max-width:9em;font-weight:700;}
// .titleImage-wrapper-home .titleImage-box-left span.title-titleImage:first-line {font-size:1.45em;}
// .portal-header-titleImage .titleImage-box-left a {font-size:1.25em;display:block;color:white;padding:15px 0 15px 1.8em;line-height:110%;text-transform:uppercase;background:url(++resource++portal.banner/play-button.png) no-repeat scroll left center / 1.4em auto transparent;}
// .portal-header-titleImage .titleImage-box-left a:hover,
// .portal-header-titleImage .titleImage-box-left a:active,
// .portal-header-titleImage .titleImage-box-left a:focus {text-decoration:underline;}
.portal-header-titleImage .titleImage-box-right {
    display: flex;
    align-items: center;
    justify-content: center;

     img {max-width: 1920px;};
}

/* Diferencias entre Home y no-Home */
.titleImage-wrapper-home {margin-bottom:4em;}

.titleImage-wrapper-noHome .titleImage-box-left span.title-titleImage {
    font-size:2.2em;
    line-height:1em;

    background-color: white;
    padding: 0.1em 0.2em;
}
.titleImage-wrapper-home .titleImage-box-right {float:right;}

.fila-portlet-banner {display: flex;}
span.imagen-squared-left,
span.imagen-squared-right {border:1px solid #CEC8C3;padding:4px;width:50%;background-color:white;}

span.imagen-squared-left {margin-right: 1em;}

div.contenedor-banner img {display:block;width:100%;}
div.imagen-landscape img {display:block;width:100%;}

span.imagen-squared-left,
span.imagen-squared-right,
div.imagen-landscape {margin-top:1em;}

div.contenedor-banner div.imagen-landscape {display:block;border:1px solid #CEC8C3;padding:4px;background-color:white;}

div.sampleview-original {display:block;margin-bottom:1em;}
div.sampleview-tamanos span {display:block;margin-bottom:1em;}
div.sampleview-original span img,
div.sampleview-tamanos span img {display:block;margin-top:0.3em;}

#content div.contenedor-banner a,
div.portletBanner a{border:2px solid white;display:block;margin:-2px;}
#content div.contenedor-banner a:hover,
div.portletBanner a:hover,
#content div.contenedor-banner a:active,
div.portletBanner a:active,
#content div.contenedor-banner a:focus,
div.portletBanner a:focus {border:2px solid #00643F;}

// Estilos Banner sponsors
.BannersSponsors {background-color: white; padding: 2em 0 3.5em 0;}
.BannersSponsors .contentBannersSponsors {text-align:center;}
.BannersSponsors .contentBannersSponsors img {margin:0.5em 1em;height:1.8em;}

.contentBannersSponsors ul {
    margin: 0;
    padding: 0;
}
.BannersSponsors ul li {
    height: 1.8em;
    margin: 0.5em 1em;
    display: inline-block;
}

/* Estilos para el banner colabora */

.BannerColabora {
    padding: 2rem 0;
    background-color: #f8f6f6;
    

    &_title {
        margin-bottom: 1rem;
        font-size: 2.65em;
        line-height: 1em;
        font-weight: 900;
        word-wrap:break-word;
    }

    .liBannerAlone {
        display: flex;
        justify-content: center;
        margin: 0 auto;

        img {
            width: 540px;
            height: auto;
        }

        @media (max-width: 575.98px) {
            width: 100%;
        }

        &_inline {}

        &_block {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .palette-highcontrast & {
        background-color: #333;
    }

    &_content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
        padding: 0;
        margin: 0;
        gap: 1em;
        justify-content: stretch;
        // display: flex;
        // flex-flow: row wrap;
        // gap: 1rem;
        width: 100%;
    
    
        @media (max-width: 1199.98px) {
            grid-template-columns: repeat(3, 1fr);
        }
        
        @media (max-width: 991.98px) {
            grid-template-columns: repeat(2, 1fr);
        }
    
        @media (max-width: 575.98px) {
            grid-template-columns: 1fr;
        }
    
        //&.banners__block {flex-direction:column; gap:1.1em; justify-content: flex-start; width: 31.8em;}
    }
   
}


.liBannerColabora {
    list-style: none;

    &.bannerFormat-block {

        .banners__block {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            //gap: 1rem;
            height: 100%;
        }
    }
}
.liBannerColabora > a {
    display:block;
    box-sizing:border-box;
}
.liBannerColabora > a:hover,
.liBannerColabora > a:active,
.liBannerColabora > a:focus {
    color:#007A2E;
    background-color:transparent !important;
    outline: 0.125rem solid #007A2E;

    .palette-highcontrast & {outline: 0.125rem solid yellow !important; border: none !important;}
}

.BannersBlock {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.spanBannerColabora,
.spanBannerColaboraHome {
    display:block;
    padding:0.5em;
    text-align:center;
    font-size:1.15em;
    color: #333;

    .palette-highcontrast & {color: white;}
}
.spanBannerColaboraHome {
    display: none;
}

.liBannerColabora img {width:100%;display:block;}

/* Corrige el solapamiento de elementos en la visualizacion del contenedor de Portlet banners */
#content div.cell.contenedor-banner {float:none;left:0;margin:0;}


/* -------------------------------------- */
/* ------------ Media Queries ----------- */
/* -------------------------------------- */



/*  *  1152px and below * */
@media screen and (max-width : 1152px) {

}

/*  *  1024px and below * */
@media screen and (max-width : 1024px)  {

}

/*  *  1000px and below * */
@media screen and (max-width : 1000px)  {

}

/*  *  800px and below * */
@media screen and (max-width : 800px) {
    .portal-header-titleImage .wrap-height {
        max-width: none;
        .title-titleImage {max-width: none;}
    }

    .portal-header-titleImage .titleImage-box-right {
        justify-content: center;
        img {width: auto; height: 100%;min-height: 35vh;}
    }
}

/*  *  767px and below * */
@media screen and (max-width : 767px) {
    .BannerColabora .container {flex-wrap: wrap; justify-content: center;}
    .ulBannerColabora {
        flex-wrap: wrap;
        justify-content: center;

        &.banners__block {width: auto;}
    }
}

/*  *  480px and below * */
@media screen and (max-width : 480px) {
    .liBannerColabora {flex-grow: 1;}
}


/* -------------------------------------- */
/* ----------- Alto Contraste ----------- */
/* -------------------------------------- */

.palette-highcontrast {

    .portal-header-titleImage .titleImage-box-left a {border:1px solid transparent;}
    .portal-header-titleImage .titleImage-box-left a:hover,
    .portal-header-titleImage .titleImage-box-left a:active,
    .portal-header-titleImage .titleImage-box-left a:focus {text-decoration:underline;border:1px solid yellow;}

    #content div.contenedor-banner a:hover,
    div.portletBanner a:hover,
    #content div.contenedor-banner a:active,
    div.portletBanner a:active,
    #content div.contenedor-banner a:focus,
    div.portletBanner a:focus {border:2px solid yellow;}

}